import popupStyles from "./userExistPopup.module.css";
import doctor from "../../images/questionnaire_doctor.png";
import { BPREVEN_CLIENT_TEST_URL, BPREVEN_CLIENT_URL } from "../../utils/utils";
import { IPopupWithoutProps } from "../../utils/types";

function UserExistPopup({ onClose, guid }: IPopupWithoutProps & { guid: string | null }) {
    const redirect_link = window.location.host.includes("test") ? BPREVEN_CLIENT_TEST_URL : BPREVEN_CLIENT_URL;
    const message = guid ? "מספר הטלפון שלך מוכר לנו כבר במערכת, האם אתה מעוניין להירשם?" : "מספר הטלפון שלך כבר קיים במערכת, הינך מועבר לכניסה למערכת"
    const onApproval = () => {
        if (guid) {
            window.location.href = `${redirect_link}/signin/private?Guid=${guid}`
        }
        else {
            window.location.href = `${redirect_link}/login`;
        }
    }
    return (
        <article className={popupStyles.popup}>
            <h2 className={popupStyles.popup__title}>אתה מוכר לנו...</h2>
            <p className={popupStyles.popup__text}>
                {message}
            </p>
            <img
                src={doctor}
                alt="Docktor"
                className={popupStyles.popup__img}
            />
            <button
                type="button"
                className={`${popupStyles.popup__nextBtn} hover`}
                onClick={onApproval}
            >
                {guid ? "לעבור לסיום הרישום" : "אישור"}
            </button>
            {guid && <button
                type="button"
                className={`${popupStyles.popup__nextBtn} hover`}
                onClick={onClose}
            >
                אני רוצה להמשיך בשאלון
            </button>}
        </article>
    );
}

export default UserExistPopup;
