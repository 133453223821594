import React, { ChangeEvent, useEffect, useRef, useState } from "react";
import DOMPurify from "dompurify";
import prevencherChatStyles from "./chat.module.css";
import Message from "../message/message";
import {
  delay,
  findNextUnansweredQuestion,
  formatDateTimeInIsrael,
  israeliPhoneRegExp,
  prevencherId,
} from "../../utils/utils";
import { useAppSelector } from "../../services/hooks";
import {
  messagesSelector,
  currentQuestionSelector,
  optionsSelector,
  currentQuestionIdSelector,
  errorSelector,
  allAnswersSelector,
  currentQuestionnaireSelector,
  allQuestionsSelector,
  questionnaireCompaniesIdSelector,
} from "../../services/selectors";
import { useActions } from "../../services/hooks/useActions";
import Loader from "../loader/loader";
import {
  useLazyGetUserByPhoneNuberQuery,
  useInsertAllAnswersQuestionsMutation,
  useLazyGetCurrentQuestionnaireByNameQuery,
  useLazyGetQuestionByIdQuery,
  useLazyGetUserInfoByIdQuery,
  useLazyGetCurrentQuestionnaireByGuidQuery,
} from "../../services/api/api";
import { IUser, IVerificationData, TQuestionOption } from "../../services/types";
import { useParams } from "react-router-dom";
import Modal from "../modal/Modal";
import UserExistPopup from "../userExistPopup/userExistPopup";
import PasswordVerificationPopup from "../passwordVerificationPopup/passwordVerificationPopup";

function Chat({ u }: { u: null | string }) {
  const { name } = useParams();

  const searchParams = new URLSearchParams(window.location.search);

  const therapistGuid = searchParams.get("therapistGuid");
  const userGuid = searchParams.get("userGuid");
  const questionnaireCompaniesTypeId = searchParams.get("questionnaireCompaniesTypeId");

  const inputRef = useRef<HTMLInputElement>(null);
  const lastElRef = useRef<HTMLDivElement | null>(null);

  const [getUserInfo] = useLazyGetUserInfoByIdQuery();
  const [getUserByPhoneNumber] = useLazyGetUserByPhoneNuberQuery();
  const [getCurrentQuestionnaireByName, { isLoading: isQuestionnaireLoading }] =
    useLazyGetCurrentQuestionnaireByNameQuery();
  const [getCurrentQuestionnaireByGuid, { isLoading: isQuestionnaireGuidLoading }] =
    useLazyGetCurrentQuestionnaireByGuidQuery();


  useEffect(() => {
    if (name) {
      getCurrentQuestionnaireByName(name);
    }
    else {
      if (userGuid && questionnaireCompaniesTypeId) {
        getCurrentQuestionnaireByGuid({ guid: userGuid, questionnaireCompaniesTypeId: questionnaireCompaniesTypeId })
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getCurrentQuestionnaireByName, name, userGuid]);

  const user = { id: "guest" };
  const messages = useAppSelector(messagesSelector);
  const options = useAppSelector(optionsSelector);
  const currentQuestionId = useAppSelector(currentQuestionIdSelector);
  const currentQuestion = useAppSelector(currentQuestionSelector);
  const currentQuestionnaire = useAppSelector(currentQuestionnaireSelector);
  const questionnaireCompaniesId = useAppSelector(
    questionnaireCompaniesIdSelector
  );
  const error = useAppSelector(errorSelector);
  const allAnswers = useAppSelector(allAnswersSelector);
  const allQuestions = useAppSelector(allQuestionsSelector);
  const { setMessages, setOptions, setAnswers } = useActions();
  const [getQuestionById] = useLazyGetQuestionByIdQuery();
  const [insertAllAnswers] = useInsertAllAnswersQuestionsMutation();
  // const [openModalRenew, setOpenModalRenew] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isUserExist, setUserExist] = useState(false);
  const [UserGuid, setUserGuid] = useState<string | null>(null);
  const [showInput, setShowInput] = useState(false);
  const [showTextArea, setShowTextArea] = useState(false);
  const [userExistPopup, setUserExistPopup] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [passwordVerifecationPopup, setPasswordVerifecationPopup] = useState<boolean>(false);
  const [currentPhone, setCurrentPhone] = useState<string | null>(null);
  const [verificationData, setVerificationData] = useState<IVerificationData | null>(null)
  const [usersId, setUsersId] = useState<number | null>(null)

  useEffect(() => {
    if (u && currentQuestionnaire?.Id.toString() === "26") {
      getUserInfo(u).then((res) => {
        if ("data" in res && res.data) {
          setAnswers({
            Id: "0",
            QuestionnaireId: currentQuestionnaire?.Id.toString(),
            QuestionsId: "23",
            UsersId: "0",
            Answer: res.data.firstName,
          });
          setAnswers({
            Id: "0",
            QuestionnaireId: currentQuestionnaire?.Id.toString(),
            QuestionsId: "24",
            UsersId: "0",
            Answer: res.data.phone,
          });
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [u, currentQuestionnaire]);

  useEffect(() => {
    if (userGuid) {
      getUserInfo(userGuid)
        .then(res => {
          if ('data' in res) {
            setUsersId(res.data?.id ?? null)
          }
        })
    }
  }, [getUserInfo, userGuid])

  useEffect(() => {
    if (
      currentQuestion &&
      !currentQuestion.NextQuestionId &&
      currentQuestion.DataTypesId !== 1 &&
      currentQuestionnaire?.CompanyId
    ) {
      insertAllAnswers({
        answers: allAnswers,
        companyId: currentQuestionnaire?.CompanyId,
        questionnaireCompaniesId: questionnaireCompaniesId,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentQuestion, insertAllAnswers, currentQuestionnaire?.CompanyId]);

  useEffect(() => {
    const nextQId = allQuestions && allQuestions[0]?.Id;
    if (currentQuestionnaire && nextQId) {
      currentQuestionnaire?.StartQuestion &&
        setMessages({
          messageId: "start",
          id: prevencherId,
          date: new Date(),
          message: currentQuestionnaire.StartQuestion,
          nextQuestionId: nextQId,
        });
      setIsLoading(true);
      nextQId &&
        delay(1500).then(() => {
          getQuestionById({
            nextQuestionId: u && currentQuestionnaire?.Id === 26 ? 76 : nextQId,
            skip: false,
            questionnaireId: currentQuestionnaire?.Id,
          }).then(() => setIsLoading(false));
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentQuestionnaire, getQuestionById, setMessages]);

  useEffect(() => {
    scrollToBottom();
  });

  useEffect(() => {
    if (currentQuestion?.DataTypesId === 2) {
      currentQuestion?.Id === 72 || currentQuestion?.Id === 19
        ? setShowTextArea(true)
        : setShowInput(true);
    }
    if (currentQuestion?.DataTypesId === 5) {
      setIsLoading(true);
      setOptions([]);
      delay(1500).then(() => {
        if (currentQuestion.NextQuestionId && currentQuestionnaire?.Id) {
          getQuestionById({
            nextQuestionId: currentQuestion.NextQuestionId,
            skip: false,
            questionnaireId: currentQuestionnaire.Id,
          }).then(() => setIsLoading(false));
        } else {
          setIsLoading(false);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentQuestion, getQuestionById, setOptions]);

  const onOptionClick = (option: TQuestionOption) => {
    setOptions([]);
    setIsLoading(true);
    setMessages({
      messageId: `o${option.Id}`,
      id: user.id,
      date: formatDateTimeInIsrael(new Date()),
      unread: false,
      message: option.Desc,
      nextQuestionId: option.NextQuestionId,
    });
    setAnswers({
      Id: "0",
      QuestionnaireId: currentQuestionnaire?.Id.toString(),
      QuestionsId: currentQuestionId.toString(),
      UsersId: usersId,
      Answer: null,
      QuestionsOptionsIds: option.Id.toString(),
      // ...(id ? { Cycle: 1 } : {}), // Only add Cycle if id exists
    });
    delay(1500).then(() => {
      setIsLoading(false);
      const nextQuestion =
        allQuestions &&
        findNextUnansweredQuestion(allQuestions, option.NextQuestionId);
      if (currentQuestionnaire?.Id) {
        if (option.NextQuestionId) {
          getQuestionById({
            nextQuestionId:
              u && option.NextQuestionId === 23
                ? 25
                : nextQuestion
                  ? nextQuestion.Id
                  : option.NextQuestionId,
            skip: !option.NextQuestionId,
            questionnaireId: currentQuestionnaire.Id,
          });
        } else {
          insertAllAnswers({
            answers: allAnswers,
            companyId: currentQuestionnaire?.CompanyId ?? 0,
            questionnaireCompaniesId: questionnaireCompaniesId,
          }).then((res) => {
            if ("data" in res) {
              console.log("data: ", res.data);

            }
          });
        }
      }
    });
  };

  const checkPhoneExist = async (inputValue: string) => {
    const res = await getUserByPhoneNumber(inputValue);
    const data = await res.data;
    if (data) {
      if ("isMail" in data) {
        setVerificationData(data as IVerificationData)
        return false;
      }
    }
    setUserExist(true);
    setUserExistPopup(true)
    const user = data as IUser;
    if (!user.email || !user.passport) {
      setUserGuid(user.guid);
    }
    return true;
  };

  const onInputSubmit = async () => {
    if (!inputValue) {
      return;
    }
    if (currentQuestion?.UserTableField === 'Phone') {
      if (!israeliPhoneRegExp.test(inputValue)) {
        setMessages({
          messageId: `${inputValue}error`,
          id: prevencherId,
          date: formatDateTimeInIsrael(new Date()),
          unread: false,
          message: `${inputValue} - מספר נייד לא תקין`,
          nextQuestionId: currentQuestion?.NextQuestionId,
        });
        return;
      } else {
        if (!therapistGuid) {
          if (!(await checkPhoneExist(inputValue))) {
            setCurrentPhone(inputValue)
            setPasswordVerifecationPopup(true)
          };
        }
      }
    }
    setShowInput(false);
    setShowTextArea(false);
    setIsLoading(true);
    setMessages({
      messageId: `${currentQuestionId}${inputValue}`,
      id: user.id,
      date: formatDateTimeInIsrael(new Date()),
      unread: false,
      message: inputValue,
      nextQuestionId: currentQuestion?.NextQuestionId,
    });
    delay(1500).then(() => {
      currentQuestionnaire?.Id &&
        setAnswers({
          Id: "0",
          QuestionnaireId: currentQuestionnaire.Id.toString(),
          QuestionsId: currentQuestionId.toString(),
          UsersId: usersId ?? "0",
          Answer: inputValue,
          // ...(id ? { Cycle: 1 } : {}), // Only add Cycle if id exists
        });
      setIsLoading(false);
      setInputValue("");
      const nextQuestion =
        allQuestions &&
        currentQuestion?.NextQuestionId &&
        findNextUnansweredQuestion(
          allQuestions,
          currentQuestion.NextQuestionId
        );
      currentQuestion?.NextQuestionId &&
        currentQuestionnaire?.Id &&
        getQuestionById({
          nextQuestionId: nextQuestion
            ? nextQuestion.Id
            : currentQuestion.NextQuestionId,
          skip: false,
          questionnaireId: currentQuestionnaire.Id,
        });
      if (!currentQuestion?.NextQuestionId) {
        insertAllAnswers({
          answers: allAnswers,
          companyId: currentQuestionnaire?.CompanyId ?? 0,
          questionnaireCompaniesId: questionnaireCompaniesId,
        }).then((res) => {
          if ("data" in res) {
          }
        });
      }
    });
  };
  const handleInputChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    let sanitizedContent = DOMPurify.sanitize(e.target.value, {
      ALLOWED_TAGS: [], // Allow only certain tags, or use [] for none
      ALLOWED_ATTR: [], // Allow no attributes
    });
    currentQuestion?.UserTableField === 'Phone'
      ? handleTelInputChange(e)
      : setInputValue(sanitizedContent);
  };
  const handleTelInputChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const inputValue = event.target.value;
    const onlyNumbers = inputValue.replace(/[^0-9]/g, ""); // Remove any non-numeric characters
    setInputValue(onlyNumbers);
  };
  const scrollToBottom = () => {
    lastElRef.current?.scrollIntoView({
      behavior: "smooth",
    });
  };
  const handleKeyDown = (
    event: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    if (event.key === "Enter") {
      onInputSubmit();
    }
  };
  const handleFocus = () => {
    inputRef?.current &&
      inputRef.current.scrollIntoView({ behavior: "smooth" });
  }; //for ios mobile

  // function hasNextQuestion(
  //   currentQuestion: TQuestion,
  //   options: TQuestionOption[]
  // ) {
  //   // Return false if neither currentQuestion.NextQuestionId nor options[0].NextQuestionId exist
  //   return !(currentQuestion?.NextQuestionId || options[0]?.NextQuestionId);
  // }
  return (
    <div
      className={`${prevencherChatStyles.section} ${prevencherChatStyles.section_POC}`}
    >
      {(isUserExist && userExistPopup) ? (
        <Modal>
          <UserExistPopup onClose={() => setUserExistPopup(false)} guid={UserGuid} />
        </Modal>
      )
        : (passwordVerifecationPopup && verificationData && currentPhone && currentQuestionnaire) ? (
          <Modal>
            <PasswordVerificationPopup
              verificationData={verificationData}
              companyId={currentQuestionnaire.CompanyId}
              phone={currentPhone}
              onClose={() => {
                setTimeout(() => {
                  setPasswordVerifecationPopup(false);
                }, 1000);
              }}
            />
          </Modal>
        )
          : messages.length > 0 &&
          messages.map((message) => (
            <Message
              key={message.messageId}
              isOwnMessage={user.id === message.id}
              message={message}
            />
          ))}
      {options.length > 0 && (
        <ul
          className={`${prevencherChatStyles.variants} ${prevencherChatStyles.variants_poc}`}
        >
          {options.map((o) => (
            <li
              className={`${prevencherChatStyles.variant}  hover`}
              key={o.Id}
              onClick={() => {
                onOptionClick(o);
              }}
            >
              <span>{o.Desc}</span>
            </li>
          ))}
        </ul>
      )}
      {showInput && (
        <div
          className={`${prevencherChatStyles.inputContainer} ${prevencherChatStyles.inputContainer_position_left}`}
        >
          <input
            type={"text"}
            ref={inputRef}
            onFocus={handleFocus}
            autoFocus={true}
            required={true}
            className={`${prevencherChatStyles.variant}  ${prevencherChatStyles.input}`}
            value={inputValue}
            maxLength={currentQuestion?.Id === 23 ? 10 : 300}
            onChange={handleInputChange}
            onKeyDown={(event) => handleKeyDown(event)}
            onClick={() => { }}
          />
          <button
            type={"button"}
            className={prevencherChatStyles.sendBtn}
            onClick={onInputSubmit}
          />
        </div>
      )}
      {showTextArea && (
        <div
          className={`${prevencherChatStyles.inputContainer} ${prevencherChatStyles.inputContainer_position_left}`}
        >
          <textarea
            autoFocus={true}
            required={true}
            className={`${prevencherChatStyles.variant}  ${prevencherChatStyles.input}`}
            value={inputValue}
            onChange={handleInputChange}
            onKeyDown={(event) => handleKeyDown(event)}
          />
          <button
            type={"button"}
            className={prevencherChatStyles.sendBtn}
            onClick={onInputSubmit}
          />
        </div>
      )}
      <div
        className={`loader ${(isLoading || isQuestionnaireLoading || isQuestionnaireGuidLoading) && "loader_visible"
          }`}
      >
        <Loader />
        <p className={prevencherChatStyles.typing}>ונצ'י מקליד</p>
      </div>
      {error && <p className={"error"}>{error}</p>}
      {(!name && !userGuid) && <p className={"error"}>לא נמצא שאלון מתאים</p>}
      <div ref={lastElRef} />
    </div>
  );
}

export default Chat;
