import { RootState } from "../types";

export const currentQuestionnaireSelector = (state: RootState) =>
  state.api.questionnaire;
export const currentQuestionSelector = (state: RootState) => state.api.question;
export const messagesSelector = (state: RootState) => state.api.messages;
export const updatesSelector = (state: RootState) => state.api.updates;
export const optionsSelector = (state: RootState) => state.api.questionsOptions;
export const currentQuestionIdSelector = (state: RootState) =>
  state.api.currentQuestionId;
export const errorSelector = (state: RootState) => state.api.error;
export const allAnswersSelector = (state: RootState) => state.api.allAnswers;
export const allQuestionsSelector = (state: RootState) =>
  state.api.allQuestions;
export const userIdSelector = (state: RootState) => state.api.userId;
export const userSelector = (state: RootState) => state.api.user;
export const appointmentSelector = (state: RootState) => state.api.appointment;
export const questionnaireCompaniesIdSelector = (state: RootState) =>
  state.api.questionnaireCompaniesId;
export const chatConnectionSelector = (state: RootState) =>
  state.chat.connection;
export const chatDataSelector = (state: RootState) => state.chat.chatData;
export const chatStateSelector = (state: RootState) => state.chat;
