import popupStyles from "./passwordVerificationPopup.module.css";
import doctor from "../../images/questionnaire_doctor.png";
import { IPopupWithoutProps } from "../../utils/types";
import { ChangeEvent, useEffect, useRef, useState } from "react";
import Tooltip from "../tooltip/tooltip";
import { IVerificationData } from "../../services/types";
import { useLazyGetUserByPhoneNuberQuery, useValidateNumberMutation } from "../../services/api/api";
import { checkValidMail } from "../../utils/utils";

function PasswordVerificationPopup({ onClose, phone, companyId, verificationData }: IPopupWithoutProps & { phone: string, companyId: number, verificationData: IVerificationData }) {
    const inputRefs = [
        useRef<HTMLInputElement>(null),
        useRef<HTMLInputElement>(null),
        useRef<HTMLInputElement>(null),
        useRef<HTMLInputElement>(null),
    ];

    const initialState = ["", "", "", ""];
    const [nums, setNums] = useState(initialState);
    const [showSendCodeTooltip, setShowSendCodeTooltip] = useState(false);
    const [currentMail, setCurrentMail] = useState<string>("");
    const [validateMessage, setValidateMessage] = useState<string>("")
    const [successsMessage, setSuccessMessage] = useState<string>("")
    const [invalidEmail, setInvalidEmail] = useState<string>("")

    const [validateNumber] = useValidateNumberMutation();
    const [getUserByPhoneNumber] = useLazyGetUserByPhoneNuberQuery();

    useEffect(() => {
        if (nums.every((num) => num !== "")) {
            handleSubmit();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [nums]);

    // Function to focus on the next input
    const focusNextInput = (index: number) => {
        const nextIndex = index + 1;
        const nextInputRef = inputRefs[nextIndex];
        if (nextInputRef && nextInputRef.current) {
            nextInputRef.current.focus();
        }
    };

    const handleNumChange = async (
        e: ChangeEvent<HTMLInputElement>,
        index: number
    ) => {
        focusNextInput(index);
        const limit = 1;
        try {
            await setNums(
                nums.map((num, index) =>
                    index.toString() === e.target.id
                        ? e.target.value.slice(-limit)
                        : num
                )
            );
        } catch (err) { }
    };

    const sendCodeAgain = () => {

        getUserByPhoneNumber(phone).then((res) => {
            if ("data" in res && res.data) {
                setShowSendCodeTooltip(true);
            }
        });
        setTimeout(() => {
            setShowSendCodeTooltip(false);
        }, 1500);
    };

    const handleSubmit = () => {

        if (!verificationData.isMail || checkValidMail(currentMail)) {
            setInvalidEmail("")
            setValidateMessage("");
            const code = nums.join("");
            const body = {
                CompanyId: companyId,
                Phone: phone,
                Code: code,
                Mail: verificationData.isMail ? currentMail : null
            };
            validateNumber({
                Type: 1,
                CompanyId: companyId,
                body: body
            }).then(res => {
                if ('data' in res) {
                    if (res.data.success === 'true') {
                        setSuccessMessage("אימות בוצע בהצלחה!")
                        onClose();
                    }
                    else {
                        setValidateMessage("הקוד שהוקש אינו נכון");
                        setNums(Array(4).fill(""));
                    }
                }
            })
        } else if (verificationData.isMail) {
            setInvalidEmail("כתובת אימייל לא תקינה")
        }
    }

    return (
        <article className={popupStyles.popup}>
            <img
                src={doctor}
                alt="Docktor"
                className={popupStyles.popup__img}
            />
            <h2 className={popupStyles.popup__title}>נא הכנס קוד אימות</h2>
            <p
                // className={popupStyles.popup__text}
                className={`${popupStyles.popup__text}`}
            >
                שלחנו קוד אימות בSMS לנייד שמסתיים ב{phone.slice(-3)}
            </p>
            <p className={popupStyles.popup__enterCodeLabel}>
                במידה ומדובר בפלאפון כשר - תישלח הודעה קולית            </p>


            <ul className={popupStyles.popup__enterCode}>
                {nums.map((num, index) => (
                    <input
                        key={index}
                        ref={inputRefs[index]}
                        onChange={(e) => handleNumChange(e, index)}
                        type="number"
                        name={index.toString()}
                        id={index.toString()}
                        className={popupStyles.popup__number}
                        placeholder="__"
                        value={nums[index]}
                        autoFocus={index === 0}
                    />
                ))}
            </ul>
            {successsMessage ? (
                <p className={`${popupStyles.popup__success_message}`}>{successsMessage}</p>
            ) :
                validateMessage && (
                    <p className={`${popupStyles.popup__invalid_message}`}>{validateMessage}</p>
                )}

            <p className={popupStyles.popup__sendAgain}>
                לא קיבלת קוד?
                <span
                    className={`${popupStyles.popup__sendAgain_underlined} hover`}
                    onClick={sendCodeAgain}
                >
                    שלח מחדש
                    <Tooltip isShown={showSendCodeTooltip}>שלחנו שוב</Tooltip>
                </span>
            </p>
            {verificationData.isMail &&
                <div
                >
                    <label htmlFor="email" className={`${popupStyles.popup__email_label}`}>
                        בנוסף, נא הכנס כתובת Email להמשך התקשרות
                    </label>
                    <div className={popupStyles.popup__email_wrap}>
                        <input
                            // ref={inputRefs[index]}
                            onChange={(e) => setCurrentMail(e.target.value)}
                            type="email"
                            name={"email"}
                            id={"email"}
                            className={`${popupStyles.popup__email_input} ${invalidEmail && popupStyles.popup__email_input_invalid} `}
                            placeholder="כתובת מייל"
                            value={currentMail}
                        />
                        <button
                            className={`${popupStyles.popup__nextBtn} hover`}
                            type="button"
                            onClick={handleSubmit}>
                            אישור ושליחה
                        </button>
                    </div>
                    {invalidEmail && (
                        <p className={`${popupStyles.popup__invalid_message}`}>{invalidEmail}</p>
                    )}
                </div>}

        </article>
    );
}

export default PasswordVerificationPopup;
