import { useState, useEffect } from "react";

const useTypingIndicator = (delay: number = 3000) => {
  const [isTyping, setIsTyping] = useState(false);
  const [lastTypedTime, setLastTypedTime] = useState<number | null>(null);

  const startTyping = () => {
    setIsTyping(true);
    setLastTypedTime(Date.now());
  };

  useEffect(() => {
    if (!isTyping) return;

    const timer = setTimeout(() => {
      if (lastTypedTime && Date.now() - lastTypedTime > delay) {
        setIsTyping(false);
      }
    }, delay);

    return () => clearTimeout(timer);
  }, [lastTypedTime, delay, isTyping]);

  return { isTyping, startTyping };
};

export default useTypingIndicator;
