import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { BASE_URL } from "../../utils/utils";
import {
  IAppointment,
  IUser,
  IValidateNumberResponse,
  IVerificationData,
  TGetQuestionByIdResponse,
  TInsertAllAnswersRequest,
  TInsertAnswerRequest,
  TQuestion,
  TReportData,
  TServerMessage,
  TValidateNumberRequest,
} from "../types";

let BASE_URL_DYNAMIC = BASE_URL;

const loadConfig = async () => {
  try {
    const response = await fetch("/config.json");
    const config = await response.json();
    console.log(config);
    BASE_URL_DYNAMIC = config.serverUrl || BASE_URL;
  } catch (error) {
    console.error("Error loading config:", error);
  }
};

// This ensures that BASE_URL is loaded before you initialize your API
await loadConfig();

export const api = createApi({
  reducerPath: "utils",
  tagTypes: ["api"],
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL_DYNAMIC,
    prepareHeaders: (headers) => {
      headers.set("Content-Type", "application/json");
      return headers;
    },
  }),
  endpoints: (build) => ({
    getUserInfoById: build.query<IUser, string>({
      query: (id) => ({
        url: `/user/User_GetUserByGuid/${id}`,
        method: "GET",
      }),
    }),
    getMyDayInfo: build.query<
      TServerMessage[],
      { id: string | null, companyId: number }
    >({
      query: ({ id, companyId }) => ({
        url: `/MyDay/GetMessagesByUserId/${companyId}/${id}`,
        method: "GET",
      }),
    }),
    getQuestionsReportsByUserId: build.query<
      TReportData,
      { userId: string; cycle: string }
    >({
      query: ({ userId, cycle }) => ({
        url: `Questions/getQuestionsReportsByUserId/26/${userId}/${cycle}`,
        method: "GET",
      }),
    }),
    getCurrentQuestionnaireByName: build.query<TReportData, string>({
      query: (name) => ({
        url: `Questions/GetCurrentQuestionnaire/${name}`,
        method: "GET",
      }),
    }),
    getCurrentQuestionnaireByGuid: build.query<TReportData, { guid: string, questionnaireCompaniesTypeId: number | string }>({
      query: ({ guid, questionnaireCompaniesTypeId }) => ({
        url: `Questions/GetCurrentQuestionnaireByType/${guid}/2`,
        method: "GET",
      }),
    }),
    getQuestionsByQuestionnaireId: build.query<
      { Questions: TQuestion }[],
      string
    >({
      query: (id) => ({
        url: `/Questions/GetAllQuestionsAndOptionsByQuestionnaireId/${id}`,
        method: "GET",
      }),
    }),
    getQuestionById: build.query<
      TGetQuestionByIdResponse,
      {
        nextQuestionId: number;
        skip?: boolean;
        questionnaireId: string | number;
      }
    >({
      query: ({ nextQuestionId, questionnaireId }) => ({
        url: `Questions/GetQuestionByQuestionnaireId/${questionnaireId}/${nextQuestionId}/0`,
        method: "GET",
      }),
    }),
    getAppointmentById: build.query<
      { Appointment: [IAppointment] },
      string | number
    >({
      query: (id) => ({
        url: `Appointments/Appointments_GetAppointmentById/${id}`,
        method: "GET",
      }),
    }),
    insertAnswerQuestion: build.mutation<
      TGetQuestionByIdResponse,
      TInsertAnswerRequest
    >({
      query: (option) => ({
        url: `/Questions/InsertAnswerQuestion`,
        method: "POST",
        body: JSON.stringify(option),
      }),
    }),
    insertAllAnswersQuestions: build.mutation<
      boolean,
      {
        answers: TInsertAllAnswersRequest[];
        companyId: number;
        questionnaireCompaniesId?: number;
      }
    >({
      query: ({ answers, companyId, questionnaireCompaniesId = 1 }) => ({
        url: `/Questions/InsertAllAnswersQuestions/${companyId}/${questionnaireCompaniesId}/0/0`,
        method: "POST",
        body: JSON.stringify(answers),
      }),
    }),
    getUserByPhoneNuber: build.query<IUser | IVerificationData, string>({
      query: (phone) => ({
        url: `user/User_GetUserByPhone/${phone}`,
        method: "GET",
      }),
    }),


    validateNumber: build.mutation<IValidateNumberResponse, { Type: number, CompanyId: number | string, body: TValidateNumberRequest }>({
      query: ({ Type, CompanyId, body }) => ({
        url: `user/User_ValidateNewUser/${Type}/${CompanyId}`,
        method: "POST",
        body: body
      }),
    }),
  }),
});

export const {
  useLazyGetQuestionByIdQuery,
  useInsertAllAnswersQuestionsMutation,
  useGetQuestionsReportsByUserIdQuery,
  useLazyGetUserInfoByIdQuery,
  useGetUserInfoByIdQuery,
  useGetMyDayInfoQuery,
  useLazyGetMyDayInfoQuery,
  useLazyGetAppointmentByIdQuery,
  useGetUserByPhoneNuberQuery,
  useLazyGetUserByPhoneNuberQuery,
  useLazyGetCurrentQuestionnaireByNameQuery,
  useLazyGetCurrentQuestionnaireByGuidQuery,
  useLazyGetQuestionsByQuestionnaireIdQuery,
  useValidateNumberMutation,
} = api;
